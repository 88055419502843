import { graphql } from 'gatsby';
import React, { useContext, useLayoutEffect, useRef, useState } from 'react';
import { css } from '@emotion/react';
import { format } from 'date-fns';
import Color from 'color';
import { ThemeContext } from '../components/theme-provider';
import SEO from '../components/seo';
import Grid from '../components/grid';
import Box from '../components/box';
import Card from '../components/card';
import BlockContent from '../components/block-content';
import FilterBar from '../components/filter-bar';
import SortButton from '../components/sort-button';
import PagePaddingTop from '../components/page-padding-top';
import { goodColors } from '../components/colors';
import ListViewCard from '../components/list-view-card';
import ViewButton from '../components/view-button';

export default function News({ data }) {
  const [articles, setArticles] = useState([]);
  const [tags, setTags] = useState([]);
  const [activeFilters, setActiveFilters] = useState([]);
  const [listViewActive, setListViewActive] = useState(0);

  const theme = useContext(ThemeContext);

  useLayoutEffect(() => {
    theme.setHeaderHide(false);
    theme.setLogoHide(false);
    theme.setLogoInvert(false);
    theme.setBackURL('/');
  }, []);

  useLayoutEffect(() => {
    setArticles(data.articles.nodes);
  }, [data.articles.nodes]);

  useLayoutEffect(() => {
    setTags(data.tags.nodes);
  }, [data.tags.nodes]);

  useLayoutEffect(() => {
    if (activeFilters.length === 0) {
      setArticles(data.articles.nodes);
    } else {
      setArticles(
        data.articles.nodes.filter((article) => {
          return article.tag.map((tag) => tag.title).some((tag) => activeFilters.includes(tag));
        })
      );
    }
  }, [activeFilters]);

  function actionFilter(filter) {
    if (!filter) {
      setActiveFilters([]);
    } else {
      if (activeFilters.includes(filter)) {
        setActiveFilters([...activeFilters.filter((el) => el !== filter)]);
      } else {
        setActiveFilters([...activeFilters, filter]);
      }
    }
  }

  function actionSort(sort) {
    if (sort === 'Recent') {
      setArticles([...articles.sort((a, b) => new Date(b.datetime) - new Date(a.datetime))]);
    } else if (sort === 'Alphabetical') {
      setArticles([...articles.sort((a, b) => a.title.localeCompare(b.title))]);
    }
  }

  return (
    <PagePaddingTop>
      <SEO title="News" />
      <Box x={10} y={10}>
        <BlockContent className="type--heading-four" content={data.news._rawIntroText} />
      </Box>
      <Box x={10} y={3}>
        <FilterBar
          activeFilters={activeFilters}
          actionFilter={actionFilter}
          filters={[...tags.map((tag) => tag.title)]}
          feedTitle="News"
        />
      </Box>
      <Box 
        x={10} 
        y={3}
        css={css`
          display: flex;
          justify-content: space-between;
        `}
      >
        <ViewButton 
          items={['Grid', 'List']} 
          active={listViewActive} 
          itemOnClick={(i) => {setListViewActive(i)}}
        />
        <SortButton active={0} items={['Recent', 'Alphabetical']} actionSort={actionSort} />
      </Box>
      <Box x={10} y={5}>
        <Grid 
          feed={true}
          css={css`
            display: ${listViewActive ? 'none' : 'grid'};
            grid-auto-flow: row dense;
          `}
        >
          {articles.length > 0 &&
            articles.map((article, i) => (
              <Card
                key={`${i} - ${article.title}`}
                url={article.url ?
                  article.url :
                  article.externalArticle ?
                    '' : 
                    `/article/${article.slug.current}`
                }
                external={article.externalArticle}
                externalTitle={article.publication}
                saveButton={(article.externalArticle) ? false : article.id}
                image={(article.hero && activeFilters.length === 0 && article.heroImage) ? article.heroImage : article.featuredImage}
                imageCrop={true}
                imageOverlay={(article.externalArticle) ? Color(goodColors[article.title.length % goodColors.length]).lighten(0.3).hex() : false}
                hero={(article.hero && activeFilters.length === 0)}
                monoText={`${article.tag
                  .map((tag) => tag.title)
                  .sort()
                  .join(', ')}${article.tag.length > 0 ? ' — ' : ''}${format(new Date(article.datetime), 'MMMM d, yyyy')}`}
                text={article.title}
                css={css`
                  grid-column-start: ${(article.hero && activeFilters.length === 0) ? 'span 8' : 'span 4'};

                  @media (max-width: 800px) {
                    grid-column-start: span 6;
                  }

                  @media (max-width: 550px) {
                    grid-column-start: span 8;
                  }
                `}
              />
            ))
          }
        </Grid>
        <Box
          y={9}
          css={css`
            display: ${listViewActive ? 'block' : 'none'};
            
          `}
        >
          {articles.length > 0 &&
            articles.map((article, i) => (
              <ListViewCard
                key={`${article.tite} - ${i}`}
                url={article.url ?
                  article.url :
                  article.externalArticle ?
                    '' : 
                    `/article/${article.slug.current}`
                }
                external={article.externalArticle}
                externalTitle={article.publication}
                monoText={article.tag
                  .map((tag) => tag.title)
                  .sort()
                  .join(', ')}
                monoTextDate={format(new Date(article.datetime), 'MMMM d, yyyy')}
                titleText={article.title}
                excerptText={article.content
                  .find((item) => item._type === 'complexBlockWrapper')
                  ?._rawText?.find((item) => item._type === 'text')
                  ?.children.filter((item) => item._type === 'span')
                  .map((span) => span.text)
                  .join(' ')
                }
              />
            ))
          }
        </Box>
      </Box>
    </PagePaddingTop>
  );
}

export const query = graphql`
  query {
    news: sanityNews(_id: { regex: "/(drafts\\\\.news)?/" }) {
      _rawIntroText(resolveReferences: { maxDepth: 10 })
    }
    articles: allSanityArticle(
      sort: { fields: datetime, order: DESC }
      filter: { slug: { current: { ne: null } } }
    ) {
      nodes {
        id
        title
        slug {
          current
        }
        hero
        externalArticle
        url
        publication
        datetime
        heroImage {
          ...Image
        }
        featuredImage {
          ...Image
        }
        tag {
          title
          slug {
            current
          }
        }
        content {
          ... on SanityComplexBlockWrapper {
            _type
            _rawText(resolveReferences: { maxDepth: 10 })
          }
        }
      }
    }
    tags: allSanityTag(sort: { fields: title }) {
      nodes {
        title
        slug {
          current
        }
      }
    }
  }
`;

import React, { useContext, useState } from 'react';
import { css } from '@emotion/react';
import SanityImage from 'gatsby-plugin-sanity-image';
import { ThemeContext } from '../components/theme-provider';
import ConditionalWrapper from './conditional-wrapper';
import { Save } from './icons';
import Link from './link';
import LinkOutBox from './link-out-box';


export default function ListViewCard({
  className,
  url,
  monoText,
  monoTextDate,
  titleText,
  external,
  externalTitle,
  excerptText,
}) {
  const [hover, setHover] = useState(false);

  const theme = useContext(ThemeContext);

  return (
    <ConditionalWrapper
      condition={url}
      wrapper={(children) => (
        <Link className={`${className} type--underline-inverse`} to={url}>
          {children}
        </Link>
      )}
    >
      <div
        className={!url ? className : ''}
        css={css`
          position: relative;
          display: block;
          padding: var(--margin3) 0 2.5rem;
          border-top: 3px solid var(--black);
        `}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        {external && (
          <LinkOutBox
            title={externalTitle}
            css={css`
              position: absolute;
              top: 0;
              right: 0;
              display: block;
              pointer-events: none;
            `}
          />
        )}
        <div
          className="type--mono-heading"
          css={css`
            margin-bottom: 1.2rem;

            @media (max-width: 500px) {
              margin-bottom: 1.1rem;
            }
          `}
        >
          {monoText}
          {monoText && <span 
            css={css`
              @media (max-width: 800px) {
                display: none;
              }
            `}
            > — </span>}
          <span
            css={css`
              @media (max-width: 800px) {
                display: block;
              }
            `}
          >{monoTextDate}</span>
        </div>
        <div
          className="type--heading-five"
          css={css`
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;
            margin-bottom: ${excerptText ? '1.5rem' : '0'};

            @media (max-width: 800px) {
              font-family: ${url ? "'Favorit Lining', serif" : "'Favorit', serif"};
            }
          `}
        >
          {titleText}
        </div>
        <div
          css={css`
            display: -webkit-box;
            max-width: 1400px;
            font-family: 'Favorit', serif;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
          `}
        >
          {excerptText}
        </div>
      </div>
    </ConditionalWrapper>
  );
}
